/*
** Identity default
** Global variables
*/

:root {
  --col-padding: 12.5px !important;
  --col-padding-md-down: 15px !important;
  --container-width: 1410px;
  --article-wrapper-max-width: auto;
  --component-border-top-width: 4px;
}

