/*
** Identity default
** Typography variables
*/

:root {
	--font-primary: "Roboto", sans-serif;
	--font-secondary: "Roboto", sans-serif;

	--base-font-size: 16px;

  --font-primary-line-height: 1.3;
  --font-secondary-line-height: 1.55;
  --article-body-line-height: 1.55;

  --h1-font-size-lg: 40;
  --h1-font-size-sm: 26;
  --h2-font-size-lg: 26;
  --h2-font-size-sm: 22;
  --h3-font-size-lg: 22;
  --h3-font-size-sm: 20;
  --h4-font-size-lg: 20;
  --h4-font-size-sm: 18;

  --heading-font-weight: 600;
  --body-font-weight: 400;
  --tag-font-weight: 600;
  --heading-menu-font-weight: 600;

  --component-heading-font-size: var(--h3-font-size-lg);
  --component-body-font-size: 16;

  --article-subheading-font-size-lg: 24;
  --article-subheading-font-size-sm: 22;
  --article-body-font-size-lg: 18;
  --article-body-font-size-sm: 16;

  --h5-font-size-lg: var(--article-body-font-size-lg);
  --h5-font-size-sm: var(--article-body-font-size-sm);

  --small-font-size: 12;
  --small-details-font-size: 14;
  --small-text-transform: initial;

  --btn-font-size: 16;
  --btn-link-font-size: 13;

  --form-font-size: 16;

  --breadcrumb-font-size: 14;

  --header-nav-link-font-size: 18;
  --megamenu-link-list-font-size: 14;

  --header-font-size-1: 18;
  --header-font-size-2: 14; 
  --header-font-size-3: 15; 
  --header-font-size-4: 14;
}
