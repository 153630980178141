/*
** Identity default
** Button variables
*/

:root {
  --btn-font-weight: 500;
  --btn-border-width: 1px;
}

