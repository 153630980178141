/*
** Identity default
** Global color variables
*/

:root {
	/* Primary colors */
	--color-primary: #033e33;
	--color-primary-text: #fff;
	--color-secondary: #dfd9f0;
	--color-secondary-text: #000;
	--color-primary-decorative: #fff4ce;
	--color-primary-decorative-text: #000;
	--color-secondary-decorative: #04493c;
	--color-secondary-decorative-text: #fff;

  /* Article element colors */
	--color-article-element--0: #033e33;
	--color-article-element-title--0: #fff;
	--color-article-element--1: #dfd9f0;
	--color-article-element-title--1: #000;
	--color-article-element--2: #f1dede;
	--color-article-element-title--2: #000;
	--color-article-element--3: #fff4ce;
	--color-article-element-title--3: #000;
	--color-article-element--4: #daedf3;
	--color-article-element-title--4: #000;
	--color-article-element--5: #b7f97e;
	--color-article-element-title--5: #234606;

  /* Element colors */
  --color-icons: #51A608;
  --color-arrows: #ee0043;
  --color-element: var(--color-icons);
  --color-link: #3661d8;
	--color-link-colored-bg: #fff;
	--color-tag: #3661d8;
  --color-tag--system: #3E7F06;
  --color-tag-bg: #3661d8;
  --color-tag-bg-text: #fff;
  --color-bg-white-component-border: #033e33;
  --color-border-top--article-components-bg: #b7f97e;
  --color-border-top--article-components-text: #234606;

  /* Btn colors */
  --color-btn-primary: #000;
  --color-btn-primary-text: #fff;
  --color-btn-secondary: #000;
  --color-btn-secondary-text: #fff;
  --color-btn-bg--primary: #b7f97e;
  --color-btn-bg--primary-text: #234606;
  --color-btn-bg--primary-border-color: #b7f97e;
  --color-btn-bg--secondary: #033e33;
  --color-btn-bg--secondary-text: #fff;
  --color-btn-bg--secondary-border-color: #000;
  --color-btn-bg--header-border-color: #fff;
  --color-btn-bg--header-text: #fff;
  --color-btn-bg--footer: #b7f97e;
  --color-btn-bg--footer-text: #234606;
  --color-btn-bg--grey-light: #000;
  --color-btn-bg--grey-light-text: #fff;


	/* Basic colors */
	--color-black: #000;
	--color-white: #fff;
	--color-grey-light: #f6f6f6; //- For backgrounds
	--color-grey-medium: #575757; //- For borders, outlines, dividers
	--color-grey-dark: #575757; //- For text: test on body an grey light backgrounds
	--color-body: #f5f4f2;
	--color-body-text: var(--color-black);
  --color-success: #008a15;
	--color-danger: #dd132b;
  --color-warning: #c75000;

	/* Background colors */
  --article-element-bg--default: var(--color-grey-light); // based on grey-light

  /* Main & section white background option variables */
  --main-white-bg-component-bg: var(--color-white); // White or Body
  --main-white-bg-component-border: 1px solid var(--color-bg-white-component-border); // none or var(--sleeve-card-border)
  --section-white-bg: rgba(255, 255, 255, 0.5); // White or opacity white
  --section-white-bg-component-bg: var(--color-white); // White or Body
  --section-white-bg-component-border: none; // none or var(--sleeve-card-border)

	/* Component colors */
	--color-footer: #032e26;
	--color-footer-text: #fff;
  --color-footer-icons: #b7f97e;
  --color-footer-arrows: #b7f97e;

	/* Header colors */
	--color-header: #032e26;
	--color-header-text: #fff;
	--color-header-link-underline: #fff;
	--color-header-icon: #fff;
	--header-box-shadow: 0 2px 4px 0 rgba(0,0,0,.05); 

	/* Overlay colors */	
  --color-overlay-start: #000000b2;
  --color-overlay-end: #00000000;
  --color-overlay-cover: #00000099;
  --color-overlay-text: #fff;
	--color-overlay-button: #fff;
	--color-overlay-button-text: #000;

	--color-overlay: var(--color-overlay-cover);
	--color-overlay-45deg: linear-gradient(45deg, var(--color-overlay-start) 0%, var(--color-overlay-end) 100%);
	--color-overlay-0deg: linear-gradient(0deg, var(--color-overlay-start) 0%, var(--color-overlay-end) 95%);
}

