/*
** Identity default
** Component variables
*/

:root {
	--footer-brand-height-lg: 60px;
	--footer-brand-height-md: 60px;

  --footer-pt-lg: 100px;
  --footer-pt-md: 50px;
  --footer-pb-lg: 100px;
  --footer-pb-md: 50px;
}
